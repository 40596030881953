<template>
  <div class="page-content">
    <div class="form-box" v-loading="loading">
      <SectionTitle
        :title="isEdit ? '编辑奖惩记录' : '新增奖惩记录'"
      ></SectionTitle>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="企业：" prop="EnterpriseId">
          <el-select
            class="mr-20"
            v-model="form.EnterpriseId"
            style="width:100%"
            @change="getStudentList"
            filterable
          >
            <el-option
              v-for="el in jobList"
              :key="el.Id"
              :label="el.EnterpriseName"
              :value="el.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检查名称：" prop="Title">
          <el-input v-model="form.Title"></el-input>
        </el-form-item>
        <el-form-item label="类型：" prop="Type">
          <el-radio-group v-model="form.Type">
            <el-radio :label="1">奖励</el-radio>
            <el-radio :label="2">惩罚</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="关联学生：" prop="StudentList">
          <el-select
            v-loading="selectLoading"
            class="mr-20"
            v-model="form.StudentList"
            multiple
            style="width:100%"
            filterable
          >
            <el-option
              v-for="el in studentList"
              :key="el.Id"
              :label="el.StudentName"
              :value="el.StudentId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检查时间：" prop="InspectTime">
          <el-date-picker
            v-model="form.InspectTime"
            type="date"
            placeholder="选择日期"
            style="width:100%"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="检查结果：" prop="Content">
          <el-input
            v-model="form.Content"
            type="textarea"
            resize="none"
            rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="附件：">
          <FileSelector v-model="form.File" />
        </el-form-item>
      </el-form>
      <div class="form-footer">
        <el-button type="primary" @click="handleSubmit" :loading="btnLoading"
          >保存</el-button
        >
        <el-button type="default" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo, insert, update } from "@/api/jobInspect";
import { queryListByEnterpriseId } from "@/api/internshipImplement";
import { getEnterpriseList } from "@/api/internshipLayout";
import FileSelector from "@/components/Fileselector";
import SectionTitle from "@/components/SectionTitle.vue";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    schoolId: {
      type: String,
      default: ""
    }
  },
  components: {
    FileSelector,
    SectionTitle
  },
  data() {
    return {
      btnLoading: false,
      selectLoading: false,
      loading: false,
      form: {
        EnterpriseId: null,
        StudentList: [],
        Title: null,
        Content: null,
        InspectTime: null,
        Type: 1,
        File: ""
      },
      jobList: [],
      studentList: [],
      rules: {
        EnterpriseId: [
          { required: true, message: "请选择企业", trigger: "blur" }
        ],
        Title: [{ required: true, message: "请输入检查名称", trigger: "blur" }],
        Type: [{ required: true, message: "请选择奖惩类型", trigger: "blur" }],
        Content: [
          { required: true, message: "请输入检查内容", trigger: "blur" }
        ],
        StudentList: [
          { required: true, message: "请选择学生", trigger: "blur" }
        ],
        InspectTime: [
          { required: true, message: "请选择检查时间", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getEnterpriseList();
        if (this.isEdit) {
          await this.getInspectInfo();
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    handleSubmit() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          try {
            this.btnLoading = true;
            this.form.SchoolId = this.schoolId;
            let IsSuccess = false;
            if (!this.isEdit) {
              const res = await insert(this.form);
              IsSuccess = res.IsSuccess;
            } else {
              const res = await update(this.form);
              IsSuccess = res.IsSuccess;
            }
            if (IsSuccess) {
              this.$message.success("保存成功");
              this.$router.go(-1);
            } else {
              this.$message.error("保存失败");
            }
          } catch (err) {
            console.log(err);
          } finally {
            this.btnLoading = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getInspectInfo() {
      const res = await getInfo(this.id);
      if (res.IsSuccess) {
        let list = [];
        res.Result.StudentList.forEach(el => {
          list.push(el.StudentId);
        });
        res.Result.StudentList = list;
        this.form = res.Result;
        await this.getStudentList(this.form.EnterpriseId);
      }
    },
    async getEnterpriseList() {
      const res = await getEnterpriseList({
        EnterpriseId: ""
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    async getStudentList(id) {
      try {
        this.selectLoading = true;
        const res = await queryListByEnterpriseId({ id });
        if (res.IsSuccess) {
          let list = res.Result;
          list.forEach(el => {
            el.StudentName = el.StudentInfo.Name;
          });
          this.studentList = list;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.selectLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-box {
  width: 850px;
  margin: 0 auto;
}
</style>
